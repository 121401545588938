import React from "react"
import tw, { styled } from "twin.macro"
import { keyframes } from "styled-components"
import { ButtonLink, H1, H3, P } from "./page-elements"
import Section from "./shared/section"
import StyledFullBackground from "./shared/styled-full-background"
import { ReactComponent as Glacier } from "../images/icons/glacier-full.svg"

const Content = {
  before: "Glacier Painting",
  header: "Experienced. Quality. Craftmanship.",
  after: "Madison Residential Painting Contractor & Refinishing Professionals",
  button: {
    text: "View Projects",
    url: "/portfolio",
  },
  image: { src: "hero1.jpg", alt: "" },
}

const Hero = () => {
  return (
    <StyledFullBackground image={Content.image.src}>
      <Section size="lg" classes="bg-transparent">
        <Overlay />
        <HeroContent>
          <H3Mono>{Content.before}</H3Mono>
          <H1Light>{Content.header}</H1Light>
          <SubText color="light">{Content.after}</SubText>
          <ButtonLink to={Content.button.url}>{Content.button.text}</ButtonLink>
        </HeroContent>
      </Section>
      <Glacier
        style={{
          display: "block",
          position: "absolute",
          width: "100%",
          bottom: "0",
          height: "auto",
          zIndex: 10,
        }}
      />
    </StyledFullBackground>
  )
}

const Overlay = styled.div`
  background-color: #000000;
  opacity: 0.6;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  z-index: -1;
  /* transition: background 0.3s, border-radius 0.3s, opacity 0.3s; */
`

const HeroContent = tw.div`
  flex
  flex-col
  w-full
  justify-between
  px-page-gutter
  md:px-0
  md:my-16
  z-50
  self-center
  justify-self-center
`

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform:  translate(0px,40px)  ;
  }

  to {
    transform:  translate(0px,0px)  ;
    opacity: 1;
  }
`

const H1Light = styled(H1)`
  ${tw`
    text-base-color
    text-center
    z-10
    uppercase
    text-4xl
    md:text-7xl
    my-6
  `}
  animation: ${fadeIn} 1s ease;
`

const H3Mono = styled(H3)`
  ${tw`
    text-base-color
    text-center
    z-10
    font-normal
    uppercase
    text-base
  `}
  letter-spacing: .50em;
`

const SubText = styled(P)`
  display: "none";
  ${tw`
    text-center
    text-sm
    z-10
    w-3/4
    md:w-1/2
    mx-auto
  `};
`

export default Hero
