import React from "react"
import VisibilitySensor from "react-visibility-sensor"
import styled, { keyframes } from "styled-components"

const fadeIn = offset => keyframes`
  from {
    opacity: 0;
    transform:  translate(0px,${offset ? offset : 300}px)  ;
  }

  to {
    transform:  translate(0px,0px)  ;
    opacity: 1;
  }

`

const SlideUpStyles = styled.div`
  opacity: 0;
  &.visible {
    animation-name: ${({ offset }: { offset?: number }) => fadeIn(offset)};
    animation-fill-mode: forwards;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-delay: ${({ index }: { index: number }) => `.${index}`}s;
  }
`
export const SlideUp: React.FC = ({ children, offset }) => {
  const [hasBeenVisible, setHasBeenVisible] = React.useState(false)
  const handleChange = (isVisible: boolean) => {
    if (isVisible) {
      setHasBeenVisible(true)
    }
  }

  return (
    <VisibilitySensor
      offset={{ top: offset ? offset : -300 }}
      partialVisibility={true}
      active={!hasBeenVisible}
      onChange={handleChange}
    >
      {({ isVisible }) => (
        <SlideUpStyles className={isVisible ? "visible" : ""} offset={offset}>
          {children}
        </SlideUpStyles>
      )}
    </VisibilitySensor>
  )
}
