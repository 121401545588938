import React from "react"
import tw from "twin.macro"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"

export const StyledFullScreenWrapper = styled.div`
  ${tw`
      w-full
      h-screen
      flex
      items-center
      justify-center
  `};
`

const StyledFullBackground: React.FC<{}> = ({ children }) => {
  let { desktop, medium, small } = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "hero1.jpg" }) {
          childImageSharp {
            fluid(quality: 95, maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        medium: file(relativePath: { eq: "hero1.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 85) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        small: file(relativePath: { eq: "hero1.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 500, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  // Art-Direction Array
  const backgroundArtDirectionStack = [
    small.childImageSharp.fluid,
    {
      ...medium.childImageSharp.fluid,
      media: `(min-width: 491px)`,
    },
    {
      ...desktop.childImageSharp.fluid,
      media: `(min-width: 1401px)`,
    },
  ]

  return (
    <StyledFullScreenWrapper>
      <BackgroundImage
        Tag="section"
        className={"w-full bg-fixed bg-cover h-screen"}
        fluid={backgroundArtDirectionStack}
        role="img"
        aria-label="Background Image"
      >
        {children}
      </BackgroundImage>
    </StyledFullScreenWrapper>
  )
}

export default StyledFullBackground
