import React from "react"
import loadable from "@loadable/component"
import SEO from "../components/seo"
import Hero from "../components/hero"
import SummarySection from "../components/summary-section"

const AboutSection = loadable(() => import("../components/about-sections"))
const GalleriesSection = loadable(
  () => import("../components/galleries-section")
)
const TestimonialSection = loadable(
  () => import("../components/testimonial-section")
)

const HowWeWorkSection = loadable(
  () => import("../components/how-we-work-section")
)

const ScheduleSection = loadable(() => import("../components/schedule-section"))

const meta = {
  title:
    "Welcome to Glacier Painting - Madison Residential Painting Contractor & Refinishing Professionals",
  description:
    "Glacier Painting offers commercial and residential painting services to the Madison Wisconsin area.",
}

const IndexPage = () => (
  <>
    <SEO title={meta.title} description={meta.description} />
    <Hero />
    <SummarySection />
    <AboutSection />
    <GalleriesSection />
    <HowWeWorkSection />
    <TestimonialSection />
    <ScheduleSection />
  </>
)

export default IndexPage
