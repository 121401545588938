import React from "react"
import tw, { styled } from "twin.macro"
import { P, H4 } from "../page-elements"
import Image from "../image"

interface HighlightBlockProps {
  title: string
  icon: string
  body: string
  alt: string
}

const Container = tw.div`
    flex
    flex-row
    justify-center
    text-left
    my-6
    md:my-8
    w-full
    md:w-1/4
    mx-2
`

const Circle = tw.div`
  flex
  justify-between
  rounded-full
  self-center
  bg-primary
  w-40
  h-40
`

const IconStyles = styled(Image)`
  ${tw`
    m-auto
  `}
`

const BlockHeader = styled(H4)`
  ${tw`
    text-white
    text-left
    text-lg
    mt-0
    mb-2
  `}
`

const Left = tw.div`
  w-1/4
  px-2
`

const Right = tw.div`
  w-3/4
  px-2
`

const BodyText = styled(P)`
  ${tw`
    text-base-color
    font-sans-secondary
  `}
`

export const HighlightBlock: React.FC<HighlightBlockProps> = ({
  title,
  icon,
  body,
  alt,
}) => {
  return (
    <Container>
      <Left>
        <IconStyles
          src={icon}
          alt={alt}
          className="w-full"
          height="100"
          width="100"
        />
      </Left>
      <Right>
        <BlockHeader>{title}</BlockHeader>
        <BodyText>{body}</BodyText>
      </Right>
    </Container>
  )
}
