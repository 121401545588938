import React from "react"
import loadable from "@loadable/component"
import { HighlightBlocks } from "./shared/highlight-blocks"
import { SlideUp } from "./shared/SlideUp"
const Section = loadable(() => import("./shared/section"))

const Content = [
  {
    title: "Commercial Painting",
    body:
      "Allow your commercial space to reflect your business. Utilize our color consulting services and customized quotes for your commercial painting project.",
    icon: "sketch.png",
    alt: "Raincloud and lightning bolt",
  },
  {
    title: "Residential Painting",
    body:
      "Put Madison’s most diligent residential painting contractors to work. Our experienced house painters will provide you with the best, hassle-free service you can find in the Madison metropolitan area.",
    icon: "apartment.png",
    alt: "Pine tree",
  },
  {
    title: "Wood Restoration",
    body:
      "As one of Madison’s leading Wood Restoration & Preservations professionals, Glacier Painting offers a wide range of wood restoration services for our residential and commercial clients.",
    icon: "paint-roller.png",
    alt: "Plant growing",
  },
]

const SummarySection = () => {
  return (
    <Section size="xl" classes="bg-aqua z-10">
      <SlideUp>
        <HighlightBlocks highlights={Content} />
      </SlideUp>
    </Section>
  )
}

export default SummarySection
