import tw from "twin.macro"

export const Container = tw.div`
    flex
    flex-wrap
    justify-between
    w-full
    h-full
    self-center
    m-auto
    py-8
    md:py-16
    overflow-hidden
`
