import React from "react"
import tw, { styled } from "twin.macro"
import { Container } from "../styles/container"

const md = "md"
const lg = "lg"
const xl = "xl"
type Size = typeof md | typeof lg | typeof xl

interface SectionProps {
  classes?: string
  size: Size
}

const generateSize = (size: Size) => {
  switch (size) {
    case "lg":
      return tw`max-w-screen-lg`
    case "md":
      return tw`max-w-screen-md`
    case "xl":
      return tw`max-w-screen-xl`
    default:
      return tw`max-w-screen-xl`
  }
}

const Content = styled.div`
  ${tw`
      md:mx-auto
      w-full
      max-w-screen-xl
      flex
    `}
  ${({ size }: { size: Size }) => generateSize(size)}
`

const Section: React.FC<SectionProps> = ({
  children,
  classes,
  size = "xl",
}) => {
  return (
    <Container className={classes}>
      <Content size={size}>{children}</Content>
    </Container>
  )
}

export default Section
